import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

import Article from "../components/article/article"

function PageReturnsRefunds() {

  const data = useStaticQuery(graphql`
  {
    allWordpressPage(filter: {wordpress_id: {eq: 458}}) {
      edges {
        node {
          title
          content
        }
      }
    }
  }
`)

  // debug
  // console.log(data)
  var articleTitle = data.allWordpressPage.edges[0].node.title
  // const parser = new DOMParser();
  // var articleTitle = parser.parseFromString(`<!doctype html><body>${articleTitle}`, 'text/html').body.textContent;
  const articleContent = data.allWordpressPage.edges[0].node.content

  return (
    <Layout>
      <SEO title={articleTitle} />
      <Article title={articleTitle} content={articleContent} />
    </Layout>
  )
}
export default PageReturnsRefunds
